// check if the url is public using regex

import platformLanguagesRaw from '@jobmojito/app/utils/data/platform-languages.json'
// get languages in format /en|/sk
const listOfLanguages = platformLanguagesRaw.filter((l)=>( (l.enable_interview_interface===true) )).map((l)=>'/'+l.i18_code).join('|');

// check if the url is public using regex
const publicUrls = [
  '^/$',
  '^/sitemap.xml$',
  '^(' + listOfLanguages + ')$',
  '^(' + listOfLanguages + ')?\\/login$',
  '^(' + listOfLanguages + ')?\\/error$',
  '^(' + listOfLanguages + ')?\\/faq$',
  '^(' + listOfLanguages + ')?\\/affiliates$',
  '^(' + listOfLanguages + ')?\\/coaching-app$',
  '^(' + listOfLanguages + ')?\\/trivia_public$',
  '^(' + listOfLanguages + ')?\\/trivia_public/.*$',
  '^(' + listOfLanguages + ')?\\/trivia_partner_test$',
  '^(' + listOfLanguages + ')?\\/trivia_partner$',
  '^(' + listOfLanguages + ')?\\/coaching-process$',
  '^(' + listOfLanguages + ')?\\/technology$',
  '^(' + listOfLanguages + ')?\\/browse-job-industries$',
  '^(' + listOfLanguages + ')?\\/manifest.webmanifest$',
  '^(' + listOfLanguages + ')?\\/terms_of_service$',
  '^(' + listOfLanguages + ')?\\/privacy_policy$',
  '^(' + listOfLanguages + ')?\\/cookie_policy$',
  '^(' + listOfLanguages + ')?\\/auth/callback$',
  '^(' + listOfLanguages + ')?\\/industry/.*$',
  '^(' + listOfLanguages + ')?\\/interview-questions-.*$',
  '^(' + listOfLanguages + ')?\\/interview-question-answer/.*$',
  '^(' + listOfLanguages + ')?\\/position/.*$',
  '^(' + listOfLanguages + ')?\\/blog$', // Matches /blog exactly
  '^(' + listOfLanguages + ')?\\/blog(/.*)?$', // Matches /blog and any subpath like /blog/post-title
]

const ignoreURLs = [
  '^/sitemap.xml$',
  '^/auth/callback$',
  '^/manifest.webmanifest$',
]

const ignoreExtendedURLs = [
  '^/$',
  '^/auth/callback$',
  '^/manifest.webmanifest$',
  '^/sitemap.xml$',
  '^(' + listOfLanguages + ')?\\/faq$',
  '^(' + listOfLanguages + ')?\\/affiliates$',
  '^(' + listOfLanguages + ')?\\/coaching-app$',
  '^(' + listOfLanguages + ')?\\/coaching-process$',
  '^(' + listOfLanguages + ')?\\/technology$',
  '^(' + listOfLanguages + ')?\\/browse-job-industries$',
  '^(' + listOfLanguages + ')?\\/manifest.webmanifest$',
  '^(' + listOfLanguages + ')?\\/auth/callback$',
  '^(' + listOfLanguages + ')?\\/industry/.*$',
  '^(' + listOfLanguages + ')?\\/interview-questions-.*$',
  '^(' + listOfLanguages + ')?\\/interview-question-answer/.*$',
]

export default function checkIsPublicUrl(url: string) {
  const isPublicUrl = publicUrls.some((urlPattern) => {
    const regex = new RegExp(urlPattern)
    return regex.test(url)
  })
  return isPublicUrl
}

export function checkIgnoreI18n(url: string, extended:boolean=false) {
  const isIgnoreUrl = (extended?ignoreExtendedURLs:ignoreURLs).some((urlPattern) => {
    const regex = new RegExp(urlPattern)
    return regex.test(url)
  })

  return isIgnoreUrl
}
